import React, { useState, useEffect } from 'react';
import { ArrowRight, Calendar } from 'lucide-react';
import { fetchLatestRequests } from '../../services/dashboardService';
import { useNavigate } from 'react-router-dom';

const LotoDashboardRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getRequests = async () => {
      try {
        const requestsData = await fetchLatestRequests();
        setRequests(requestsData);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        setLoading(false);
      }
    };

    getRequests();
  }, []);

  const handleViewAllRequests = () => {
    navigate('/permits'); // Adjust this path to match your route configuration
  };

  if (loading) {
    return (
      <div className="bg-gray-800 rounded-lg p-4">
        <h3 className="text-lg font-medium text-emerald-400 mb-4">Latest Safety Document Requests</h3>
        <div className="text-gray-400 text-center py-4">Loading requests...</div>
      </div>
    );
  }

  if (requests.length === 0) {
    return (
      <div className="bg-gray-800 rounded-lg p-4">
        <h3 className="text-lg font-medium text-emerald-400 mb-4">Latest Safety Document Requests</h3>
        <div className="text-gray-400 text-center py-4">No pending requests found</div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800 rounded-lg">
      <div className="p-4">
        <h3 className="text-lg font-medium text-emerald-400 mb-4">Latest Safety Document Requests</h3>

        <div className="space-y-3">
          {requests.map((request) => (
            <div 
              key={request.id} 
              className="p-3 rounded-lg bg-gray-750 hover:bg-gray-700 transition-all duration-200 border border-gray-700"
            >
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium text-white">{request.workToBeDone}</span>
                <div className="flex items-center text-xs text-gray-400">
                  <Calendar className="w-3 h-3 mr-1" />
                  {new Date(request.createdAt.toDate()).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                  })}
                </div>
              </div>
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <span className="text-xs text-emerald-400">{request.location}</span>
                  <span className="text-xs text-purple-400">{request.company}</span>
                </div>
                <div className="text-xs text-gray-400">
                  Requested by: {request.fullName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button 
        onClick={handleViewAllRequests}
        className="w-full flex items-center justify-center px-4 py-3 text-sm text-emerald-400 border-t border-gray-700 hover:bg-gray-700 transition-colors gap-2 font-medium"
      >
        See all requests
        <ArrowRight className="w-4 h-4" />
      </button>
    </div>
  );
};

export default LotoDashboardRequests;
