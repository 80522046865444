import React from 'react';

const ObservationView = ({ isOpen, onClose, observation }) => {
  if (!isOpen || !observation) return null;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const renderIsolationForm = (data) => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-white mb-4">Isolation in place</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormField label="Key Register Recorded" value={data.keyRegisterRecorded} />
        <FormField label="Permit Referenced" value={data.permitReferenced} />
        <FormField label="Reference Number Consistent" value={data.referenceNumberConsistent} />
        <FormField label="Safety Lock Applied" value={data.safetyLockApplied} />
        <FormField label="Isolation Secure" value={data.isolationSecure} />
        <FormField label="Competent Person Aware" value={data.competentPersonAware} />
        <FormField label="No Interference" value={data.noInterference} />
        <FormField label="Safety Lock Retained" value={data.safetyLockRetained} />
      </div>
      {data.comments && (
        <FormField label="Comments" value={data.comments} fullWidth />
      )}
    </div>
  );

  const renderControlForm = (data) => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-white mb-4">Control and Access Monitoring</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormField label="Entry Point Identified" value={data.entryPointIdentified} />
        <FormField label="Doors Lockable" value={data.doorsLockable} />
        <FormField label="Keys With Monitor" value={data.keysWithMonitor} />
        <FormField label="Logbooks Complete" value={data.logbooksComplete} />
        <FormField label="Blue Visit Access" value={data.blueVisitAccess} />
        <FormField label="Room Cleanliness" value={data.roomCleanliness} />
        <FormField label="No Unauthorized Storage" value={data.noUnauthorizedStorage} />
        <FormField label="Equipment Condition" value={data.equipmentCondition} />
        <FormField label="Door Secure Check" value={data.doorSecureCheck} />
      </div>
    </div>
  );

  const renderSafetyForm = (data) => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-white mb-4">Safety Document Review</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormField label="Safety Document Available" value={data.safetyDocAvailable} />
        <FormField label="Safety Document Correct" value={data.safetyDocCorrect} />
        <FormField label="Precautions Understood" value={data.precautionsUnderstood} />
        <FormField label="Supporting Docs Referenced" value={data.supportingDocsReferenced} />
        <FormField label="Sections Complete" value={data.sectionsComplete} />
        <FormField label="Working Party Register" value={data.workingPartyRegister} />
        <FormField label="HEAT Trained" value={data.heatTrained} />
        <FormField label="Transfer Record" value={data.transferRecord} />
        <FormField label="Clearing Process" value={data.clearingProcess} />
      </div>
      {data.comments && (
        <FormField label="Comments" value={data.comments} fullWidth />
      )}
    </div>
  );

  const FormField = ({ label, value, fullWidth = false }) => (
    <div className={fullWidth ? "col-span-2" : ""}>
      <label className="block text-sm font-medium text-gray-300 mb-1">
        {label}
      </label>
      {fullWidth ? (
        <div className="w-full bg-gray-700 rounded-lg px-3 py-2 text-white">
          {value || 'N/A'}
        </div>
      ) : (
        <div className="w-full bg-gray-700 rounded-lg px-3 py-2 text-white">
          {value || 'N/A'}
        </div>
      )}
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-xl p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-white">Observation Details</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors duration-200"
          >
            ✕
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <FormField label="Auditor Name" value={observation.auditorName} />
          <FormField label="Position" value={observation.position} />
          <FormField label="Location" value={observation.location} />
          <FormField label="Date" value={formatDate(observation.date)} />
        </div>

        {observation.formType === 'isolation' && renderIsolationForm(observation.formData)}
        {observation.formType === 'control' && renderControlForm(observation.formData)}
        {observation.formType === 'safety' && renderSafetyForm(observation.formData)}

        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg text-sm font-medium transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ObservationView;