import React, { useState, useEffect } from 'react';
import { Clock } from 'lucide-react';
import { fetchPermitStats } from '../../services/dashboardService';

const PermitMonitoring = () => {
  const [permitStats, setPermitStats] = useState({
    LOA: { prepared: 0, issued: 0, closed: 0 },
    EPTW: { prepared: 0, issued: 0, closed: 0 },
    MPTW: { prepared: 0, issued: 0, closed: 0 }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStats = async () => {
      setLoading(true);
      try {
        const stats = await fetchPermitStats();
        setPermitStats(stats);
      } catch (error) {
        console.error('Error fetching permit stats:', error);
      } finally {
        setLoading(false);
      }
    };

    getStats();
  }, []); // Only fetch once when component mounts

  if (loading) {
    return (
      <div className="bg-gray-900 rounded-xl p-6 shadow-lg">
        <div className="flex items-center justify-center h-64">
          <div className="text-emerald-400">Loading stats...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 rounded-xl p-6 shadow-lg">
      <div className="flex items-center mb-6">
        <Clock className="w-6 h-6 text-emerald-400 mr-2" />
        <h2 className="text-2xl font-semibold text-white">Loto Safety Documents Monitoring Stats</h2>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {Object.entries(permitStats).map(([type, stats]) => (
          <div key={type} className="bg-gray-800 rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-emerald-400 to-purple-400"></div>
            
            <div className="mb-6">
              <h3 className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-purple-400 bg-clip-text text-transparent">
                {type}
              </h3>
            </div>

            <div className="space-y-4">
              <div className="flex flex-col">
                <span className="text-4xl font-bold text-yellow-400">{stats.prepared}</span>
                <span className="text-sm text-gray-400 mt-1">Prepared</span>
              </div>
              <div className="flex flex-col">
                <span className="text-4xl font-bold text-emerald-400">{stats.issued}</span>
                <span className="text-sm text-gray-400 mt-1">Issued</span>
              </div>
              <div className="flex flex-col">
                <span className="text-4xl font-bold text-purple-400">{stats.closed}</span>
                <span className="text-sm text-gray-400 mt-1">Closed</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermitMonitoring;
