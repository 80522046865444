import React from 'react';
import { RadioGroup } from '../RadioGroup';

const ControlForm = ({ formData, handleChange }) => {
  return (
    <>
      <h3 className="text-lg font-semibold text-white mb-4">Control and Access Monitoring</h3>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Auditor Name
          </label>
          <input
            type="text"
            name="auditorName"
            value={formData.auditorName}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Position
          </label>
          <input
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Area/Location
          </label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Date
          </label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="space-y-4">
        <RadioGroup
          label="1. Confirm Area/Room is controlled with a designated entry/exit point identified"
          name="entryPointIdentified"
          value={formData.entryPointIdentified}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="2. Confirm that the Area/Room has doors fitted which are lockable, and these doors are locked and signage is in place"
          name="doorsLockable"
          value={formData.doorsLockable}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="3. Confirm that keys for doors are with the assigned door monitor for Area/Room"
          name="keysWithMonitor"
          value={formData.keysWithMonitor}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="4. Confirm Door Monitor Logbooks are available and legible with all sections complete and sufficient detail recorded"
          name="logbooksComplete"
          value={formData.logbooksComplete}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="5. Confirm that Door Monitor has access to live blue visit"
          name="blueVisitAccess"
          value={formData.blueVisitAccess}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="6. Request entry into room and confirm the cleanliness of the room is sufficient"
          name="roomCleanliness"
          value={formData.roomCleanliness}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="7. Confirm no materials/equipment is being stored unless agreed with SAP team and must be free of combustible material"
          name="noUnauthorizedStorage"
          value={formData.noUnauthorizedStorage}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="8. Confirm equipment within room is in good condition and have no signs of damage"
          name="equipmentCondition"
          value={formData.equipmentCondition}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="9. Confirm that door monitor once exiting room, checks that door has closed and is locked"
          name="doorSecureCheck"
          value={formData.doorSecureCheck}
          onChange={handleChange}
          required
        />

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Comments
          </label>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            rows="4"
          />
        </div>
      </div>
    </>
  );
};

export default ControlForm;
