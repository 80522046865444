import React from 'react';
import { RadioGroup } from '../RadioGroup';

const IsolationForm = ({ formData, handleChange }) => {
  return (
    <>
      <h3 className="text-lg font-semibold text-white mb-4">Active Monitoring (Isolations in place)</h3>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Auditor Name
          </label>
          <input
            type="text"
            name="auditorName"
            value={formData.auditorName}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Position
          </label>
          <input
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Area/Location
          </label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Date
          </label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="space-y-4">
        <RadioGroup
          label="1. Confirm Isolation in place has been recorded on Key Register"
          name="keyRegisterRecorded"
          value={formData.keyRegisterRecorded}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="2. If Isolation is associated with a Permit to Work confirm this has been referenced on document or associated Isolation Control Certificate"
          name="permitReferenced"
          value={formData.permitReferenced}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="3. Confirm Isolation unique reference number is correctly referenced on Key register and Permit to Work and/or Isolation Control Certificate and number is consistent across both"
          name="referenceNumberConsistent"
          value={formData.referenceNumberConsistent}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="4. Confirm at Point of Isolation that a safety lock has been applied and has correct reference number"
          name="safetyLockApplied"
          value={formData.safetyLockApplied}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="5. Confirm that Isolation is secure and has caution notice applied"
          name="isolationSecure"
          value={formData.isolationSecure}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="6. If a live Permit to Work is in use confirm with the Competent Person in charge of work that he is aware of the Point of isolation and it has been demonstrated to him/her before commencing work, and has issued safety lock key with him"
          name="competentPersonAware"
          value={formData.competentPersonAware}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="7. Confirm no signs of interference with Point of Isolation"
          name="noInterference"
          value={formData.noInterference}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="8. Confirm safety lock key has been retained within safety lock cabinet or if associated with a Permit to Work has been locked within Lockout box with 2 locks applied 1 to be retained within Safety Lock Cabinet and 2nd Issued to safety document holder"
          name="safetyLockRetained"
          value={formData.safetyLockRetained}
          onChange={handleChange}
          required
        />

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Comments
          </label>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            rows="4"
          />
        </div>
      </div>
    </>
  );
};

export default IsolationForm;
