import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'lucide-react';
import keyPlan from '../assets/keyplan.png';
import FormattedDate from '../Layout/FormattedDate';
import PermitMonitoring from './PermitMonitoring';
import LotoDasboardRequests from './LotoDashboardRequests';
import EnergisationRequests from './EnergisationRequests';



const StatCard = ({ title, value, change, icon }) => (
  <div className="bg-gray-800 rounded-lg p-4 flex flex-col">
    <div className="text-xs text-gray-400 mb-2">{title}</div>
    <div className="text-2xl font-bold mb-2">{value}</div>
    <div className={`text-xs ${change > 0 ? 'text-green-400' : 'text-red-400'}`}>
      {change > 0 ? '+' : ''}{change}%
    </div>
    <div className="mt-2 text-gray-400">{icon}</div>
  </div>
);

const CalendarItem = ({ time, title, person, color }) => (
  <div className="flex items-center mb-4">
    <div className={`w-2 h-2 rounded-full ${color} mr-2`}></div>
    <div className="flex-grow">
      <div className="text-sm">{title}</div>
      <div className="text-xs text-gray-400">{time} • {person}</div>
    </div>
    <button className="text-gray-400">•••</button>
  </div>
);

function Dashboard() {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">Loading...</div>;
  }

  if (!currentUser) {
    navigate('/login');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold">Welcome, {currentUser.email}</h1>
        <p className="text-gray-400">Role: {currentUser.role}</p>
   
        
      </div>

      <div className="bg-gray-800 rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
        
          <div className="flex items-center space-x-4">
            <select className="bg-gray-700 rounded-full px-4 py-2">
              <option>Day 1</option>
              <option>Day 2</option>
              <option>Day 3</option>
              <option>Day 4</option>
              <option>Day 5</option>
              <option>Day 6</option>
              <option>Day 7</option>
            </select>
            <div className="bg-gray-700 rounded-full px-4 py-2 flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              <span><FormattedDate /></span>
            </div>
          </div>
        </div>
        <img src={keyPlan} alt="Logo" className="w-full mb-4 p-2 blur-sm" />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2  p-4">
            <PermitMonitoring />
          </div>

          <div className="space-y-4">
            <div className="bg-gray-700 rounded-lg p-4">
              <LotoDasboardRequests />
            </div>
          
          </div>
        </div>

        <EnergisationRequests />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
          
        
         
        </div>
       

       
      </div>
    </div>
  );
}

function AdminDashboard() {
  return <div className="text-lg text-gray-300">Admin Dashboard Content</div>;
}

function APDashboard() {
  return <div className="text-lg text-gray-300">AP Dashboard Content</div>;
}

function SAPDashboard() {
  return <div className="text-lg text-gray-300">SAP Dashboard Content</div>;
}

export default Dashboard;
