import React from 'react';

import logo from '../../../assets/logo.png';
import { useUserDetails } from '../hooks/useUserDetails';

const MPTW_Template = ({ permitData = {} }) => {
  // Use the useUserDetails hook to fetch all user data
  const { preparedByUser, issuerData, closedByUser } = useUserDetails(permitData);
  
  const {
    number = '001',
    location = '',
    workToBeDone = '',
    preparedBy = {},
    issuedDetails,
    closeDetails
  } = permitData;

  // Format timestamp
  const formatDateTime = (timestamp) => {
    if (!timestamp) return { time: '', date: '' };
    try {
      const date = new Date(timestamp.seconds * 1000);
      
      return {
        time: date.toLocaleTimeString('en-GB', { 
          hour: '2-digit', 
          minute: '2-digit',
          hour12: false
        }),
        date: date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        })
      };
    } catch (error) {
      console.error('Error formatting date:', error);
      return { time: '', date: '' };
    }
  };

  const preparedDateTime = formatDateTime(preparedBy?.timestamp);
  const issuedDateTime = formatDateTime(issuedDetails?.issuedBy?.timestamp);
  const closedDateTime = formatDateTime(closeDetails?.closedBy?.timestamp);

  // Function to render supporting docs checkboxes
  const renderSupportingDocs = () => {
    const docs = issuedDetails?.supportingDocs || {};
    return (
      <div className="mt-2">
        <p>
          METHOD STATEMENT {docs.methodStatement ? '☑' : '□'} &nbsp;
          RISK ASSESSMENT {docs.riskAssessment ? '☑' : '□'} &nbsp;
          WORK PROCEDURES {docs.workProcedures ? '☑' : '□'} &nbsp;
          OTHER {docs.other ? '☑' : '□'}
        </p>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center" id="mptw-template">
      {/* First Page */}
      <div className="w-[210mm] h-[297mm] bg-white shadow-lg p-1 text-sm flex flex-col mb-8">
        {/* Header */}
        <div className="bg-gray-400 p-4 flex items-center justify-between">
          {/* Left container: Logo */}
          <div className="flex-1 flex items-center">
            <img src={logo} alt="Logo" className="w-45 h-14" />
          </div>

          {/* Middle container: MECHANICAL PERMIT TO WORK */}
          <div className="flex-1 flex justify-center">
            <h1 className="text-xl font-bold text-white text-center">MECHANICAL PERMIT TO WORK</h1>
          </div>

          {/* Right container: MPTW Number */}
          <div className="flex-1 flex justify-end">
            <div className="bg-gray-500 text-white font-semibold text-xl p-2">
              MPTW-WTX-{number}
            </div>
          </div>
        </div>

        {/* Section 1 */}
        <div className="border-2 text-gray-950 p-1">
          <h2 className="font-bold">1. LOCATION AND DESCRIPTION OF WORK</h2>
          <p>(i) LOCATION: {location}</p>
          <p>(ii) WORK TO BE DONE: {workToBeDone}</p>
        </div>

        {/* Section 2 */}
        <div className="border-2 p-1 text-gray-950">
          <h2 className="font-bold">2. SAFETY CONTROLS</h2>
          <p>(I) MAIN POINTS OF ISOLATION:</p>
          <p className="text-xs">{permitData.mainPointsOfIsolation || 'No isolation points specified.'}</p>
          
          <p>(III) ACTIONS TAKEN TO AVOID DANGER:</p>
          <p className="text-xs">{permitData.actionsToAvoidDanger || 'No actions specified.'}</p>
          
          <p>(IV) FURTHER PRECAUTIONS DURING WORK:</p>
          <p className="text-xs">{permitData.furtherPrecautions || 'No further precautions specified.'}</p>

          <p>(V) VARIABLE PRECAUTIONS:</p>
          <p className="text-xs">{permitData.variablePrecautions || 'No variable precautions specified.'}</p>

          <p>(VI) ATTACHED ICC:</p>
          <p className="text-xs">{permitData.attachedICC || 'No ICC details provided.'}</p>
        </div>

        {/* Section 3 */}
        <div className="p-1 border-2 text-black">
          <h2 className="font-bold">3. PREPARATION</h2>
          <p className="text-xs mb-2">I CONFIRM THAT THE PRECAUTIONS IN SECTION 2 HAVE BEEN CARRIED OUT AND WILL BE MAINTAINED UNTIL THIS MECHANICAL PERMIT TO WORK IS CANCELLED.</p>
          <p className="text-xs mb-4">I CERTIFY THAT THE PRECAUTIONS CARRIED OUT IN SECTION 2 ARE ADEQUATE TO PROVIDE SAFETY FROM THE SYSTEM FOR THE WORK SPECIFIED IN SECTION 1</p>
          
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-black p-1 font-bold text-xs w-[40%]">
                  NAME 
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  SIGNATURE
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  CONTACT DETAILS*
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  TIME
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  DATE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-1 h-10">
                  {preparedByUser?.name || ''}
                </td>
                <td className="border border-black p-1 h-10">
                  {preparedByUser?.signature && (
                    <img 
                      src={preparedByUser.signature} 
                      alt="Signature" 
                      className="h-8 object-contain mx-auto"
                    />
                  )}
                </td>
                <td className="border border-black p-1 h-10">
                  {preparedByUser?.phoneNumber || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {preparedDateTime.time || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {preparedDateTime.date || ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Section 4 */}
        <div className="p-1 border-2 text-black">
          <h2 className="font-bold">4. ISSUE</h2>
          <p className="text-xs mb-4">AUTHORISED PERSON RESPONSIBLE FOR THE ISSUE OF THIS DOCUMENT</p>
          
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-black p-1 font-bold text-xs w-[40%]">
                  AUTHORISED PERSON - NAME 
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  SIGNATURE
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  CONTACT DETAILS*
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  TIME
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  DATE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-1 h-10">
                  {issuerData?.name || ''}
                </td>
                <td className="border border-black p-1 h-10">
                  {issuerData?.signature && (
                    <img 
                      src={issuerData.signature} 
                      alt="Signature" 
                      className="h-8 object-contain mx-auto"
                    />
                  )}
                </td>
                <td className="border border-black p-1 h-10">
                  {issuerData?.phoneNumber || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {issuedDateTime.time || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {issuedDateTime.date || ''}
                </td>
              </tr>
            </tbody>
          </table>

          <p className="mt-4">SUPPORTING DOCUMENTATION & ITEMS ATTACHED*</p>
          {renderSupportingDocs()}
        </div>

        {/* Section 5 */}
        <div className="p-1 border-2 text-black">
          <h2 className="font-bold">5. RECEIPT</h2>
          <p className="text-xs mb-2">I BEING THE COMPETENT PERSON IN CHARGE UNDERSTAND AND ACCEPT MY RESPONSIBILITIES FOR THE WORKS SPECIFIED IN SECTION 1</p>
          <p className="text-xs mb-2">I HEREBY RECEIVE THIS DOCUMENT & ANY SUPPORTING DOCUMENTATION & ITEMS AS DETAILED IN SECTION 4</p>
          <p className="text-xs mb-4">IN ADDITION GENERAL SAFETY REQUIREMENTS HAVE BEEN MET AND RISKS MITIGATED</p>
          
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-black p-1 font-bold text-xs w-[40%]">
                  NAME 
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  SIGNATURE
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  CONTACT DETAILS*
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  TIME
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  DATE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-1 h-10">
                  {issuedDetails?.issuedTo?.name || ''}
                </td>
                <td className="border border-black p-1 h-10">
                  {issuedDetails?.issuedTo?.signature && (
                    <img 
                      src={issuedDetails.issuedTo.signature} 
                      alt="Signature" 
                      className="h-8 object-contain mx-auto"
                    />
                  )}
                </td>
                <td className="border border-black p-1 h-10">
                  {issuedDetails?.issuedTo?.contactNumber || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {issuedDateTime.time || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {issuedDateTime.date || ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Second Page */}
      <div className="w-[210mm] h-[297mm] bg-white shadow-lg p-1 text-sm flex flex-col">
        {/* Section 6 */}
        <div className="p-2 mb-4 border-2 text-black">
          <h2 className="font-bold">6. TRANSFER RECORD</h2>
          <p className="text-xs mb-4">A MECHANICAL PERMIT TO WORK MAY BE SURRENDERED BY THE COMPETENT PERSON AFTER COMPLETING SECTION 6, TRANSFER RECORD OF THIS SAFETY DOCUMENT. A MECHANICAL PERMIT TO WORK SHALL ONLY BE REISSUED BY AN AUTHORISED PERSON WHO WILL REISSUE TO THE SAME STANDARD AS IF IT WAS BEING ISSUED FOR THE FIRST TIME.</p>
          
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-black p-1 font-bold text-xs w-[35%]">
                  SURRENDER BY
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[30%]">
                  AP
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[35%]">
                  RE-ISSUE TO
                </th>
              </tr>
            </thead>
            <tbody>
              {permitData.transferHistory?.map((transfer, index) => (
                <tr key={index}>
                  <td className="border border-black p-2">
                    <div className="mb-2">
                      <strong>NAME:</strong> {transfer.fromUser?.name || ''}
                    </div>
                    <div className="mb-2">
                      <strong>CONTACT:</strong> {transfer.fromUser?.contactNumber || ''}
                    </div>
                    <div className="mb-2">
                      {transfer.fromUser?.signature && (
                        <img 
                          src={transfer.fromUser.signature} 
                          alt="Signature" 
                          className="h-8 object-contain"
                        />
                      )}
                    </div>
                    <div>
                      <strong>TIME:</strong> {formatDateTime(transfer.timestamp).time || ''}<br/>
                      <strong>DATE:</strong> {formatDateTime(transfer.timestamp).date || ''}
                    </div>
                  </td>

                  <td className="border border-black p-2">
                    <div className="mb-2">
                      <strong>NAME:</strong> {transfer.transferredBy?.name || ''}
                    </div>
                    <div className="mb-2">
                      <strong>CONTACT:</strong> {transfer.transferredBy?.phoneNumber || ''}
                    </div>
                    <div>
                      {transfer.transferredBy?.signature && (
                        <img 
                          src={transfer.transferredBy.signature} 
                          alt="Signature" 
                          className="h-8 object-contain"
                        />
                      )}
                    </div>
                  </td>

                  <td className="border border-black p-2">
                    <div className="mb-2">
                      <strong>NAME:</strong> {transfer.toUser?.name || ''}
                    </div>
                    <div className="mb-2">
                      <strong>CONTACT:</strong> {transfer.toUser?.contactNumber || ''}
                    </div>
                    <div className="mb-2">
                      {transfer.toUser?.signature && (
                        <img 
                          src={transfer.toUser.signature} 
                          alt="Signature" 
                          className="h-8 object-contain"
                        />
                      )}
                    </div>
                    <div>
                      <strong>TIME:</strong> {formatDateTime(transfer.timestamp).time || ''}<br/>
                      <strong>DATE:</strong> {formatDateTime(transfer.timestamp).date || ''}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Section 7 */}
        <div className="p-2 mb-4 border-2 text-black">
          <h2 className="font-bold">7. CLEARANCE</h2>
          <p className="text-xs mb-2">I CERTIFY THAT ALL PERSONS WORKING UNDER THIS MECHANICAL PERMIT TO WORK HAVE BEEN WITHDRAWN FROM AND WARNED IT IS NO LONGER SAFE TO CARRY OUT THE WORK IN SECTION 1. ALL PERSONS HAVE BEEN REMOVED FROM THE WORK AREA. ALL GEAR, TOOLS & LOOSE MATERIALS HAVE ALSO BEEN REMOVED AND ALL COVERS, GUARDS AND ACCESS DOORS HAVE BEEN REPLACED.</p>
          <p className="text-xs mb-4">THIS MECHANICAL PERMIT TO WORK IS CLEARED. THE FOLLOWING EXCEPTIONS ARE RECORDED *</p>
          
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-black p-1 font-bold text-xs w-[40%]">
                  NAME 
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  SIGNATURE
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  CONTACT DETAILS*
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  TIME
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  DATE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-1 h-10">
                  {permitData.clearanceDetails?.currentHolder?.name || ''}
                </td>
                <td className="border border-black p-1 h-10">
                  {permitData.clearanceDetails?.currentHolder?.signature && (
                    <img 
                      src={permitData.clearanceDetails.currentHolder.signature} 
                      alt="Signature" 
                      className="h-8 object-contain mx-auto"
                    />
                  )}
                </td>
                <td className="border border-black p-1 h-10">
                  {permitData.clearanceDetails?.currentHolder?.contactNumber || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {formatDateTime(permitData.clearanceDetails?.clearedAt).time || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {formatDateTime(permitData.clearanceDetails?.clearedAt).date || ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Section 8 */}
        <div className="text-black border-2 p-2">
          <h2 className="font-bold">8. CANCELLATION</h2>
          <p className="text-xs mb-4">I CERTIFY THAT THE AUTHORISED PERSON HAS BEEN INFORMED OF ANY EXCEPTIONS ASSOCIATED WITH THIS MECHANICAL PERMIT TO WORK & PERMISSION FROM THE AUTHORISED PERSON HAS BEEN GRANTED TO CANCEL THIS MECHANICAL PERMIT TO WORK.</p>
          
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-black p-1 font-bold text-xs w-[40%]">
                  NAME 
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  SIGNATURE
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[20%]">
                  CONTACT DETAILS*
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  TIME
                </th>
                <th className="border border-black p-1 font-bold text-xs w-[10%]">
                  DATE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-1 h-10">
                  {closedByUser?.name || ''}
                </td>
                <td className="border border-black p-1 h-10">
                  {closedByUser?.signature && (
                    <img 
                      src={closedByUser.signature} 
                      alt="Signature" 
                      className="h-8 object-contain mx-auto"
                    />
                  )}
                </td>
                <td className="border border-black p-1 h-10">
                  {closedByUser?.phoneNumber || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {closedDateTime.time || ''}
                </td>
                <td className="border border-black p-1 h-10 text-center">
                  {closedDateTime.date || ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MPTW_Template;
