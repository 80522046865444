import React from 'react';
import { RadioGroup } from '../RadioGroup';

const SafetyForm = ({ formData, handleChange }) => {
  return (
    <>
      <h3 className="text-lg font-semibold text-white mb-4">Safety Document Review</h3>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Auditor Name
          </label>
          <input
            type="text"
            name="auditorName"
            value={formData.auditorName}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Position
          </label>
          <input
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Area/Location
          </label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Date
          </label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="space-y-4">
        <RadioGroup
          label="1. Confirm Safety Document is available at point of work and Competent Person in charge of work is readily available"
          name="safetyDocAvailable"
          value={formData.safetyDocAvailable}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="2. Confirm with Competent Person that Safety Document is correct for work being undertaken (Location, Access to, Work to be done)"
          name="safetyDocCorrect"
          value={formData.safetyDocCorrect}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="3. Confirm that the Competent Person understands the precautions set out within the Safety Document (This includes identifying applied points of isolation if applicable)"
          name="precautionsUnderstood"
          value={formData.precautionsUnderstood}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="4. Confirm that the Competent Person has referenced Supporting Documentation and associated Keys (Access & Safety Lock)"
          name="supportingDocsReferenced"
          value={formData.supportingDocsReferenced}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="5. Confirm all sections of Preparation, Issue and Acceptance of Safety Document are complete and that these match status within SASTpro system"
          name="sectionsComplete"
          value={formData.sectionsComplete}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="6. Confirm that Competent Person has fully completed a Working Party Register and has briefed the Working Party of Safety Document requirements and associated RAMS cross referenced on Safety Document"
          name="workingPartyRegister"
          value={formData.workingPartyRegister}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="7. Confirm that all members of the Working Party are HEAT trained"
          name="heatTrained"
          value={formData.heatTrained}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="8. Confirm Transfer Record has been completed correctly and matches status within SASTpro"
          name="transferRecord"
          value={formData.transferRecord}
          onChange={handleChange}
          required
        />

        <RadioGroup
          label="9. Confirm that Competent Person understands the process for clearing the Safety Document"
          name="clearingProcess"
          value={formData.clearingProcess}
          onChange={handleChange}
          required
        />

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Comments
          </label>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
            rows="4"
          />
        </div>
      </div>
    </>
  );
};

export default SafetyForm;
