import { addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../../services/firebase';

export const saveBoard = async (boardData) => {
  try {
    const boardsRef = collection(db, 'boards');
    const docRef = await addDoc(boardsRef, {
      name: boardData.name,
      status: boardData.status,
      createdAt: new Date(),
      paths: boardData.paths,
      connectedBreakers: []
    });
    
    console.log("Board saved successfully with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error saving board:", error);
    throw error;
  }
};

export const getBoards = async () => {
  try {
    const boardsRef = collection(db, 'boards');
    const snapshot = await getDocs(boardsRef);
    const boards = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return boards;
  } catch (error) {
    console.error("Error fetching boards:", error);
    throw error;
  }
};