import { collection, query, getDocs, where, orderBy, limit } from 'firebase/firestore';
import { db } from './firebase';

export const fetchPermitStats = async () => {
  try {
    const permitsRef = collection(db, 'permits');
    const querySnapshot = await getDocs(permitsRef);
    
    // Initialize counters
    const stats = {
      LOA: { prepared: 0, issued: 0, closed: 0 },
      EPTW: { prepared: 0, issued: 0, closed: 0 },
      MPTW: { prepared: 0, issued: 0, closed: 0 }
    };

    // Count permits by type and status
    querySnapshot.forEach((doc) => {
      const permit = doc.data();
      if (stats[permit.permitType]) {
        if (permit.status === 'Prepared') {
          stats[permit.permitType].prepared++;
        } else if (permit.status === 'Issued') {
          stats[permit.permitType].issued++;
        } else if (permit.status === 'Closed') {
          stats[permit.permitType].closed++;
        }
      }
    });

    return stats;
  } catch (error) {
    console.error('Error fetching permit stats:', error);
    throw error;
  }
};

export const fetchLatestRequests = async () => {
  try {
    const requestsRef = collection(db, 'permitRequests');
    const q = query(
      requestsRef,
      where('status', '==', 'Pending'),
      orderBy('createdAt', 'desc'),
      limit(3)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching requests:', error);
    throw error;
  }
};
