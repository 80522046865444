import React from 'react';

export const RadioGroup = ({ label, name, value, onChange, required }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-300 mb-2">
        {label}
      </label>
      <div className="flex space-x-4">
        <label className="inline-flex items-center">
          <input
            type="radio"
            name={name}
            value="yes"
            checked={value === "yes"}
            onChange={onChange}
            className="form-radio text-blue-600 h-4 w-4"
            required={required}
          />
          <span className="ml-2 text-gray-300">Yes</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            name={name}
            value="no"
            checked={value === "no"}
            onChange={onChange}
            className="form-radio text-blue-600 h-4 w-4"
          />
          <span className="ml-2 text-gray-300">No</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            name={name}
            value="na"
            checked={value === "na"}
            onChange={onChange}
            className="form-radio text-blue-600 h-4 w-4"
          />
          <span className="ml-2 text-gray-300">N/A</span>
        </label>
      </div>
    </div>
  );
};
