import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import FeedbackModal from './modals/FeedbackModal';
import ReCAPTCHA from 'react-google-recaptcha';

function PermitRequests() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    success: false,
    message: ''
  });
  
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    company: '',
    documentHolder: '',
    telephone: '',
    dateRequired: '',
    workDuration: '',
    rams: '',
    workType: '',
    location: [],
    equipment: '',
    workToBeDone: '',
    roomNamesNumbers: '',
    isolationRequired: '',
    sapApRequired: '',
    otherInfo: '',
    customLocation: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter(item => item !== value)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const resetForm = () => {
    setFormData({
      email: '',
      fullName: '',
      company: '',
      documentHolder: '',
      telephone: '',
      dateRequired: '',
      workDuration: '',
      rams: '',
      workType: '',
      location: [],
      equipment: '',
      workToBeDone: '',
      roomNamesNumbers: '',
      isolationRequired: '',
      sapApRequired: '',
      otherInfo: '',
      customLocation: ''
    });
    setCaptchaToken(null);
  };

  const closeModal = () => {
    setModalState(prev => ({ ...prev, isOpen: false }));
    if (modalState.success) {
      resetForm();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Check if CAPTCHA is completed
      if (!captchaToken) {
        throw new Error('Please complete the CAPTCHA verification');
      }

      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        throw new Error('Please enter valid email addresses');
      }

      // Phone number validation (basic)
      const phoneRegex = /^\+?[\d\s-]{10,}$/;
      if (!phoneRegex.test(formData.telephone)) {
        throw new Error('Please enter a valid telephone number');
      }

      const submitData = {
        ...formData,
        location: formData.location === 'Other' ? formData.customLocation : formData.location,
        status: 'Pending',
        createdAt: new Date(),
        captchaVerified: true
      };
      delete submitData.customLocation;
      
      await addDoc(collection(db, 'permitRequests'), submitData);

      setModalState({
        isOpen: true,
        success: true,
        message: 'Your LOTO Safety Document Request has been submitted successfully. We will review your request and get back to you shortly.'
      });

    } catch (error) {
      console.error('Error submitting request:', error);
      setModalState({
        isOpen: true,
        success: false,
        message: error.message || 'An error occurred while submitting the request. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="max-w-4xl mx-auto mb-8 bg-gray-800 text-gray-100 p-8 rounded-lg shadow-lg">
      <h3 className="text-3xl font-bold mb-6 mt-3 text-blue-400">Safety Document Request</h3>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-300 mb-1">Full Name Requestor</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="documentHolder" className="block text-sm font-medium text-gray-300 mb-1">Safety Document Holder</label>
            <input
              type="text"
              id="documentHolder"
              name="documentHolder"
              value={formData.documentHolder}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="telephone" className="block text-sm font-medium text-gray-300 mb-1">Telephone Number</label>
            <input
              type="tel"
              id="telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label htmlFor="dateRequired" className="block text-sm font-medium text-gray-300 mb-1">Date Required</label>
            <input
              type="date"
              id="dateRequired"
              name="dateRequired"
              value={formData.dateRequired}
              onChange={handleChange}
              min={new Date(Date.now() + 86400000).toISOString().split('T')[0]}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div>
          <label htmlFor="workDuration" className="block text-sm font-medium text-gray-300 mb-1">Expected Duration of Work</label>
          <input
            type="text"
            id="workDuration"
            name="workDuration"
            value={formData.workDuration}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="rams" className="block text-sm font-medium text-gray-300 mb-1">Risk Assessment/Method Statement Number (RAMS)</label>
          <input
            type="text"
            id="rams"
            name="rams"
            value={formData.rams}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Is this work Mechanical or Electrical?</p>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="workType"
                value="Mechanical"
                checked={formData.workType === 'Mechanical'}
                onChange={handleChange}
                className="form-radio text-blue-500"
                required
              />
              <span className="ml-2">Mechanical</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="workType"
                value="Electrical"
                checked={formData.workType === 'Electrical'}
                onChange={handleChange}
                className="form-radio text-blue-500"
                required
              />
              <span className="ml-2">Electrical</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="workType"
                value="Other"
                checked={formData.workType === 'Other'}
                onChange={handleChange}
                className="form-radio text-blue-500"
                required
              />
              <span className="ml-2">Other</span>
            </label>
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="location" className="block text-sm font-medium text-gray-300 mb-1">Location</label>
          <select
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">-- Select a location --</option>
            <optgroup label="EYD Locations">
              {[...Array(8)].map((_, i) => (
                <option key={`EYD${i}`} value={`EYD ${i}`}>EYD {i}</option>
              ))}
            </optgroup>
            <optgroup label="EBA Locations">
              {[...Array(8)].map((_, i) => (
                <option key={`EBA${i}`} value={`EBA ${i}`}>EBA {i}</option>
              ))}
            </optgroup>
            <optgroup label="FSA Locations">
              <option value="FSA 0A">FSA 0A</option>
              <option value="FSA 0B">FSA 0B</option>
            </optgroup>
            <optgroup label="DCH Locations">
              {[...Array(7)].map((_, i) => (
                <React.Fragment key={`DCH${i+1}`}>
                  <option value={`DCH ${i+1}A`}>DCH {i+1}A</option>
                  <option value={`DCH ${i+1}B`}>DCH {i+1}B</option>
                </React.Fragment>
              ))}
            </optgroup>
            <optgroup label="MYD Locations">
              {[...Array(8)].map((_, i) => (
                <option key={`MYD${i}`} value={`MYD ${i}`}>MYD {i}</option>
              ))}
            </optgroup>
            <optgroup label="Other Locations">
              <option value="Roof">Roof</option>
              <option value="Other">Other</option>
            </optgroup>
          </select>
          {formData.location === 'Other' && (
            <input
              type="text"
              id="customLocation"
              name="customLocation"
              value={formData.customLocation}
              onChange={handleChange}
              placeholder="Please specify the location"
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
              required
            />
          )}
        </div>

        <div>
          <label htmlFor="equipment" className="block text-sm font-medium text-gray-300 mb-1">Equipment to be worked on</label>
          <input
            type="text"
            id="equipment"
            name="equipment"
            value={formData.equipment}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="workToBeDone" className="block text-sm font-medium text-gray-300 mb-1">Work to be done</label>
          <textarea
            id="workToBeDone"
            name="workToBeDone"
            value={formData.workToBeDone}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            required
          ></textarea>
        </div>

        <div>
          <label htmlFor="roomNamesNumbers" className="block text-sm font-medium text-gray-300 mb-1">Room Names and Numbers</label>
          <input
            type="text"
            id="roomNamesNumbers"
            name="roomNamesNumbers"
            value={formData.roomNamesNumbers}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Will an isolation be required?</p>
          <div className="flex space-x-4">
            {['YES', 'NO', 'UNSURE'].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="isolationRequired"
                  value={option}
                  checked={formData.isolationRequired === option}
                  onChange={handleChange}
                  className="form-radio text-blue-500"
                  required
                />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Will an SAP/AP be required to operate equipment?</p>
          <div className="flex space-x-4">
            {['YES', 'NO', 'UNSURE'].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="sapApRequired"
                  value={option}
                  checked={formData.sapApRequired === option}
                  onChange={handleChange}
                  className="form-radio text-blue-500"
                  required
                />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="otherInfo" className="block text-sm font-medium text-gray-300 mb-1">Any other information</label>
          <textarea
            id="otherInfo"
            name="otherInfo"
            value={formData.otherInfo}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            required
          ></textarea>
        </div>
        {/* reCAPTCHA */}
        <div className="flex justify-center pt-4">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={setCaptchaToken}
            theme="dark"
          />
        </div>
        
        <button 
          type="submit" 
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitting || !captchaToken}
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Submitting...
            </span>
          ) : (
            'Submit Request'
          )}
        </button>
      </form>

      <FeedbackModal 
        isOpen={modalState.isOpen}
        onClose={closeModal}
        success={modalState.success}
        message={modalState.message}
      />
    </div>
  );
}

export default PermitRequests;
