import React from 'react';

const energisationRequests = [
  {
    id: "ER001",
    requester: "Alex Turcu",
    asset: "SDC-A1-2",
    status: "Pending QaQc",
    date: "2024-01-15",
    priority: "High"
  },
  {
    id: "ER002",
    requester: "Stephen Bryson",
    asset: "PMDC-A1-2A",
    status: "Pending GcCx",
    date: "2025-01-18",
    priority: "Medium"
  }
];

const EnergisationRequests = () => {
  return (
    <div className="mt-6">
      <div className="bg-gray-800 rounded-lg p-4">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-semibold text-white">Energisation Requests</h3>
          <div className="flex space-x-2">
            <button className="px-3 py-1 text-sm bg-gray-700 rounded-lg hover:bg-gray-600">Filter</button>
            <button className="px-3 py-1 text-sm bg-emerald-600 rounded-lg hover:bg-emerald-500">See All Request</button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-700">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">ID</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Requester</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Asset</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Status</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Date</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Priority</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {energisationRequests.map((request) => (
                <tr key={request.id} className="hover:bg-gray-700">
                  <td className="px-4 py-3 text-sm">{request.id}</td>
                  <td className="px-4 py-3 text-sm">{request.requester}</td>
                  <td className="px-4 py-3 text-sm">{request.asset}</td>
                  <td className="px-4 py-3 text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      request.status === 'Approved' ? 'bg-green-900 text-green-300' :
                      request.status === 'Pending' ? 'bg-yellow-900 text-yellow-300' :
                      request.status === 'Energised' ? 'bg-green-900 text-yellow-300' :
                      'bg-blue-900 text-blue-300'
                    }`}>
                      {request.status}
                    </span>
                  </td>
                  <td className="px-4 py-3 text-sm">{request.date}</td>
                  <td className="px-4 py-3 text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      request.priority === 'High' ? 'bg-red-900 text-red-300' :
                      request.priority === 'Medium' ? 'bg-orange-900 text-orange-300' :
                      'bg-green-900 text-green-300'
                    }`}>
                      {request.priority}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EnergisationRequests;
