import React, { useState, useCallback } from 'react';
import useObservations from '../../hooks/useObservations';
import IsolationForm from './Forms/IsolationForm';
import ControlForm from './Forms/ControlForm';
import SafetyForm from './Forms/SafetyForm';

const NewObservationModal = ({ isOpen, onClose }) => {
  const [formType, setFormType] = useState('isolation');
  const [formData, setFormData] = useState({
    formType: 'isolation',
    auditorName: '',
    position: '',
    location: '',
    date: '',
    // Isolation in place fields
    keyRegisterRecorded: '',
    permitReferenced: '',
    referenceNumberConsistent: '',
    safetyLockApplied: '',
    isolationSecure: '',
    competentPersonAware: '',
    noInterference: '',
    safetyLockRetained: '',
    comments: '',
    // Control and Access Monitoring fields
    auditorName: '',
    position: '',
    location: '',
    date: '',
    entryPointIdentified: '',
    doorsLockable: '',
    keysWithMonitor: '',
    logbooksComplete: '',
    blueVisitAccess: '',
    roomCleanliness: '',
    noUnauthorizedStorage: '',
    equipmentCondition: '',
    doorSecureCheck: '',
    // Safety Document Review fields
    auditorName: '',
    position: '',
    location: '',
    date: '',
    safetyDocAvailable: '',
    safetyDocCorrect: '',
    precautionsUnderstood: '',
    supportingDocsReferenced: '',
    sectionsComplete: '',
    workingPartyRegister: '',
    heatTrained: '',
    transferRecord: '',
    clearingProcess: '',
    comments: ''
  });

  const { addObservation } = useObservations();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addObservation({
      ...formData,
      formType
    });
    
    if (result.success) {
      onClose();
    } else {
      // You might want to show an error message here
      console.error('Failed to add observation');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold text-white mb-4">Safety Audit Form</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Form Type
            </label>
            <select
              name="formType"
              value={formType}
              onChange={(e) => setFormType(e.target.value)}
              className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-blue-500"
              required
            >
              <option value="isolation">Isolation in place</option>
              <option value="control">Control and Housekeeping</option>
              <option value="safety">Safety Document Review</option>
            </select>
          </div>

          {formType === 'isolation' && (
            <IsolationForm formData={formData} handleChange={handleChange} />
          )}

          {formType === 'control' && (
            <ControlForm formData={formData} handleChange={handleChange} />
          )}

          {formType === 'safety' && (
            <SafetyForm formData={formData} handleChange={handleChange} />
          )}

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm font-medium transition-colors duration-200"
            >
              Create Observation
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewObservationModal;
