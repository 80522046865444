import HVMVSchematic from '../assets/HVMVMimic.jpg';
import React, { useState, useRef, useEffect } from 'react'; 
import { Pencil, Circle, Cable, Hand, Save, ZoomIn, ZoomOut, Undo } from 'lucide-react';
import { useDrawing } from './MimicDrawing/UseDrawing';
import { DrawingSurface } from './MimicDrawing/DrawingSurface';
import { saveBoard, getBoards } from './MimicDrawing/boardsDB';

const Mimic = () => {
  const [scale, setScale] = useState(1);
  const selectedColor = '#808080'; // Fixed gray color
  const selectedWidth = 3; // Fixed thick width
  const [mode, setMode] = useState('select'); // 'select', 'board', 'breaker', 'circuit'
  const [breakers, setBreakers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [circuits, setCircuits] = useState([]);
  const [showNameDialog, setShowNameDialog] = useState(false);
  const [savedBoards, setSavedBoards] = useState([]);

  const svgRef = useRef(null);
  const containerRef = useRef(null);

  const {
    isDrawing,
    currentLine,
    lines,
    startPoint,
    showGuides,
    startDrawing,
    draw,
    endDrawing,
    undoLastLine,
    setLines
  } = useDrawing(scale);

  useEffect(() => {
    const loadBoards = async () => {
      try {
        const boards = await getBoards();
        console.log("Loaded boards:", boards);
        setSavedBoards(boards);
        
        // Convert the saved paths back to lines format
        const savedLines = boards.flatMap(board => 
          board.paths.map(path => ({
            points: path.points,
            color: '#ff0000', // or whatever default color you want
            width: 3 // or whatever default width you want
          }))
        );
        setLines(savedLines);
      } catch (error) {
        console.error("Error loading boards:", error);
      }
    };
  
    loadBoards();
  }, []);

  const handleLineEndClick = (point, rotation) => {
    if (mode === 'breaker') {
      setBreakers(prev => [...prev, { 
        id: `breaker-${Date.now()}`,
        x: point.x, 
        y: point.y, 
        rotation,
        isOpen: true,
        connections: {
          top: null,
          bottom: null
        }
      }]);
    }
  };

  const handleSave = () => {
    console.log("Save button clicked"); // Debug log
    console.log("Current lines:", lines); // Check if we have lines
    console.log("Current mode:", mode);   // Check what mode we're in
    
    if (lines.length > 0) {
      console.log("Showing name dialog"); // Debug log
      setShowNameDialog(true);
    }
  };

  const handleNameSubmit = async (name) => {
    if (!name.trim()) return;
  
    try {
      const boardData = {
        name: name.trim(),
        status: 'Open',
        paths: lines.map(line => ({
          points: line.points.map(point => ({
            x: point.x,
            y: point.y
          }))
        }))
      };
  
      console.log("Saving board data:", boardData);
      const boardId = await saveBoard(boardData);
      console.log("Board saved with ID:", boardId);
  
      setLines([]);
      setShowNameDialog(false);
      
    } catch (error) {
      console.error('Error saving board:', error);
      // Add error handling here
    }
  };

  const toggleBreakerState = (index) => {
    setBreakers(prev => prev.map((breaker, i) => 
      i === index ? { ...breaker, isOpen: !breaker.isOpen } : breaker
    ));
  };

  const handleZoom = (delta) => {
    setScale(prevScale => {
      const newScale = prevScale + delta;
      return newScale > 0.1 ? newScale : 0.1;
    });
  };

  const undoLastBreaker = () => {
    setBreakers(prev => prev.slice(0, -1));
  };

  return (
    <div className="w-full h-full relative overflow-hidden border border-gray-300">
      <div className="absolute left-4 top-10 -translate-y-10 flex flex-col gap-4 bg-white p-4 rounded-lg shadow-lg z-10">
        {/* Zoom Controls */}
        <div className="flex flex-col items-center gap-2 pb-4 border-b border-gray-200">
          <button 
            onClick={() => handleZoom(0.1)}
            className="w-10 h-10 bg-blue-500 text-white rounded-lg flex items-center justify-center hover:bg-blue-600 transition-colors"
          >
            <ZoomIn size={20} />
          </button>
          <button 
            onClick={() => handleZoom(-0.1)}
            className="w-10 h-10 bg-blue-500 text-white rounded-lg flex items-center justify-center hover:bg-blue-600 transition-colors"
          >
            <ZoomOut size={20} />
          </button>
        </div>

        {/* Mode Selection */}
        <div className="flex flex-col items-center gap-2 pb-4 border-b border-gray-200">
          <button
            onClick={() => setMode('select')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'select' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Select Mode"
          >
            <Hand size={20} />
          </button>
          <button
            onClick={() => setMode('board')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'board' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Board Mode"
          >
            <Pencil size={20} />
          </button>
          <button
            onClick={() => setMode('breaker')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'breaker' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Breaker Mode"
          >
            <Circle size={20} />
          </button>
          <button
            onClick={() => setMode('circuit')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'circuit' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Circuit Mode"
          >
            <Cable size={20} />
          </button>
        </div>


        {/* Save Button - Only show when there are lines and in board/circuit mode */}
        {lines.length > 0 && (mode === 'board' || mode === 'circuit') && (
          <button
            onClick={handleSave}
            className="w-full bg-green-500 text-white px-3 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-green-600 transition-colors"
          >
            <Save size={16} />
            Save {mode === 'board' ? 'Board' : 'Circuit'}
          </button>
        )}

        {/* Undo Button */}
        <div className="pt-4 border-t border-gray-200">
          <button 
            onClick={mode === 'breaker' ? undoLastBreaker : undoLastLine}
            className="w-full bg-red-500 text-white px-3 py-2 rounded-lg flex items-center justify-center gap-2"
          >
            <Undo size={16} />
            Undo
          </button>
        </div>
      </div>

      {/* Drawing Surface */}
      <div ref={containerRef} className="relative w-full h-full overflow-auto pl-24">
        <div style={{ 
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: '5520px',
          height: '2003px',
          position: 'relative'
        }}>
          <img
            src={HVMVSchematic}
            alt="Schematic Background"
            className="absolute top-0 left-0 w-full h-full object-contain"
          />
          
          <DrawingSurface 
            svgRef={svgRef}
            width={5520}
            height={2003}
            showGuides={showGuides}
            startPoint={startPoint}
            lines={lines}
            currentLine={currentLine}
            breakers={breakers}
            boards={boards}
            circuits={circuits}
            mode={mode}
            onMouseDown={(e) => (mode === 'board' || mode === 'circuit') && 
              startDrawing(e, svgRef, selectedColor, selectedWidth)}
            onMouseMove={(e) => (mode === 'board' || mode === 'circuit') && 
              draw(e, svgRef)}
            onMouseUp={endDrawing}
            onMouseLeave={endDrawing}
            onLineEndClick={handleLineEndClick}
            onBreakerClick={toggleBreakerState}
            savedBoards={savedBoards}
          />
        </div>
      </div>

      {showNameDialog && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl min-w-[300px]">
      <h3 className="text-lg font-semibold mb-4">
        Name your board
      </h3>
      <input
        type="text"
        className="w-full px-3 py-2 border rounded mb-4"
        placeholder="Enter board name"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleNameSubmit(e.target.value);
          }
        }}
        autoFocus
      />
      <div className="flex justify-end gap-2">
        <button
          onClick={() => setShowNameDialog(false)}
          className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
        >
          Cancel
        </button>
        <button
          onClick={(e) => {
            const input = e.target.parentElement.parentElement.querySelector('input');
            handleNameSubmit(input.value);
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default Mimic;
