import { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../services/firebase';

const useObservations = () => {
  const [observations, setObservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, 'observations'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const observationsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setObservations(observationsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const addObservation = async (formData) => {
    try {
      const observationData = {
        formType: formData.formType,
        auditorName: formData.auditorName,
        position: formData.position,
        location: formData.location,
        date: formData.date,
        status: 'new',
        createdAt: serverTimestamp(),
        formData: formData
      };

      await addDoc(collection(db, 'observations'), observationData);
      return { success: true };
    } catch (error) {
      console.error('Error adding observation:', error);
      return { success: false, error };
    }
  };

  return { observations, loading, addObservation };
};

export default useObservations;
